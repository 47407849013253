
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 分销商后台管理系统--菜单
 * @author jing
 * @date 2021/1/19 9:49
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 * @attribute distributorType 分销商类型(ALIGJ: 阿里国际分销、YMS：云卖分销商)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006663'),
      icon: '&#xe6b2;',
      path: '/distributionMarket',
      menuKey: 'distributor_distributionMarket',
      distributorType: ['ALIGJ']
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005493'),
      icon: '&#xe618;',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000790'),
          path: '/myInventory',
          menuKey: 'distributor_myInventory',
          distributorType: ['YMS']
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006664'),
          path: '/commodityList',
          menuKey: 'distributor_commodityList',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006665'),
          path: '/generateSalesRecords',
          menuKey: 'distributor_generateSalesRecords',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000008'),
          type: 'toListing',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在角色管理中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'distributor_shelfTools',
          distributorType: ['YMS', 'ALIGJ']
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006666'),
      icon: '&#xe6fe;',
      path: '/automaticRules',
      menuKey: 'distributor_automaticRules',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006667'),
      icon: '&#xe604;',
      path: '/platformStoreList',
      menuKey: 'distributor_platformStoreList',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000078'),
      icon: '&#xe63a;',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006668'),
          path: '/collectionSupplier',
          menuKey: 'distributor_collectionSupplier',
          distributorType: ['YMS']
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001036'),
      icon: '&#xe668;',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004412'),
          path: '/platformOrder',
          menuKey: 'distributor_platformOrder',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006669'),
              path: '/platformDetails',
              menuKey: 'distributor_platformDetails',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006670'),
              path: '/addTemuOrder',
              menuKey: 'distributor_addTemuOrder',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001322')}`,
          path: '/myOrder',
          menuKey: 'distributor_myOrder',
          distributorType: ['YMS'],
          children: [
            {
              name: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004852')}`,
              path: '/myOrderDetails',
              menuKey: 'distributor_myOrderDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004874'),
              path: '/addCloudSalesOrder',
              menuKey: 'distributor_addCloudSalesOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004413'),
          path: '/distributorStockList',
          menuKey: 'distributor_distributorStockList',
          distributorType: ['YMS'],
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001364'),
              path: '/createBackupOrder',
              menuKey: 'distributor_createBackupOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006646'),
              path: '/distributorStockListDetails',
              menuKey: 'distributor_distributorStockListDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001037'),
      icon: '&#xe645;',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002085'),
          path: '/rechargeRecord',
          menuKey: 'distributor_rechargeRecord',
          distributorType: ['YMS'],
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002721'),
          path: '/financialJournal',
          menuKey: 'distributor_financialJournal',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006614'),
      icon: '&#xe68e;',
      children: [
        {
          name: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002892')}`,
          path: '/logisticsSettings',
          menuKey: 'distributor_logisticsSettings',
          distributorType: ['YMS'],
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001074'),
          path: '/ownLogistics',
          menuKey: 'distributor_ownLogistics',
          distributorType: ['YMS'],
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006616'),
          path: '/smartRule',
          menuKey: 'distributor_smartRule',
          distributorType: ['YMS'],
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006671'),
      icon: '&#xe61b;',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006672'),
          path: '/roleManage',
          menuKey: 'distributor_roleManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006673'),
          path: '/userManage',
          menuKey: 'distributor_userManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000285'),
      path: '/newsList',
      menuKey: 'distributor_newsList',
      distributorType: ['YMS', 'ALIGJ'],
      menuHide: true,
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006674'),
          path: '/newsListDetails',
          menuKey: 'distributor_newsListDetails',
          distributorType: ['YMS', 'ALIGJ'],
          menuHide: true
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006675'),
      icon: '&#xe627;',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000591'),
          path: '/basicInformation',
          menuKey: 'distributor_basicInformation',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006676'),
          path: '/securityInformation',
          menuKey: 'distributor_securityInformation',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004373'),
              path: '/modifyMobile',
              menuKey: 'distributor_modifyMobile',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004375'),
              path: '/modifyMailbox',
              menuKey: 'distributor_modifyMailbox',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004617'),
              path: '/modifyPassword',
              menuKey: 'distributor_modifyPassword',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004316'),
          path: '/iossManageList',
          menuKey: 'distributor_iossManageList',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006641'),
      icon: '&#xe6a9;',
      type: 'importTask',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006643'),
          path: '/exportTask',
          menuKey: 'distributor_exportTask',
          distributorType: ['YMS', 'ALIGJ'],
        }
      ]
    },
    {
      name: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006662')}`,
      type: 'ymsShopping',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006677'),
          menuKey: 'shop_shopList',
          distributorType: ['YMS'],
        }
      ]
    }
  ]
};
