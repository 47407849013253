
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 菜单
 * @author gan
 * @date: 2020/12/17 11:09
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005190'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'yms_statistics',
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005493'),
      icon: '&#xe749;',
      type: 'productList',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002078'),
          path: '/productList',
          menuKey: 'yms_productList',
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002513'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006584'),
              path: '/productDetails',
              menuKey: 'yms_productDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002660'),
          path: '/category',
          menuKey: 'yms_category',
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006585'),
          path: '/sizeTemplateManage',
          menuKey: 'yms_sizeTemplateManage',
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006586'),
              path: '/updateSizeTemplate',
              menuKey: 'yms_updateSizeTemplate',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006587'),
          path: '/approvalList',
          menuKey: 'yms_approvalList',
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006588'),
              path: '/approvalDetails',
              menuKey: 'yms_approvalDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002516'),
              path: '/findSimilarProducts',
              menuKey: 'yms_findSimilarProducts',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002089'),
          path: '/itemAttributes',
          menuKey: 'yms_itemAttributes'
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001036'),
      icon: '&#xe665;',
      type: 'orderList',
      children: [
        {
          name: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001322')}`,
          path: '/orderList',
          menuKey: 'yms_orderList',
          children: [
            {
              name: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004852')}`,
              path: '/orderListDetails',
              menuKey: 'yms_orderListDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006589'),
              path: '/supplierOrderDetails',
              menuKey: 'yms_supplierOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006590'),
          path: '/distributorStockList',
          menuKey: 'yms_distributorStockList'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006591'),
          path: '/supplierStockList',
          menuKey: 'yms_supplierStockList',
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006592'),
              path: '/supplierStockOrderDetails',
              menuKey: 'yms_supplierStockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002088'),
          path: '/supplierOrders',
          menuKey: 'yms_supplierOrders'
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000018'),
      icon: '&#xe679;',
      type: 'wms',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002080'),
          path: '/wms/inventoryLevels',
          menuKey: 'yms_wms_inventoryLevels'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006593'),
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006594'),
              path: '/billLadingManage',
              menuKey: 'yms_billLadingManage',
              children: [
                {
                  name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006595'),
                  path: '/billLadingManageDetails',
                  menuKey: 'yms_billLadingManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001818'),
              path: '/billWarehousing',
              menuKey: 'yms_billWarehousing'
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001819'),
              path: '/commoditySorting',
              menuKey: 'yms_commoditySorting'
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002079'),
              path: '/commoditySticker',
              menuKey: 'yms_commoditySticker'
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001822'),
              path: '/commodityShelves',
              menuKey: 'yms_commodityShelves'
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005686'),
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002424'),
              path: '/assembleGeneratePickingList',
              menuKey: 'yms_assembleGeneratePickingList'
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006596'),
              path: '/assembleGeneratePickingDetails',
              menuKey: 'yms_assembleGeneratePickingDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006589'),
              path: '/assembleSupplierOrderDetails',
              menuKey: 'yms_assembleSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006597'),
              path: '/assemblePickingList',
              menuKey: 'yms_assemblePickingList'
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002389'),
              path: '/assemblePickingListDetails',
              menuKey: 'yms_assemblePickingListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001222'),
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002424'),
              path: '/wms/generateOrderList',
              menuKey: 'yms_wms_generateOrderList'
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006596'),
              path: '/generateOrderDetails',
              menuKey: 'yms_generateOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006589'),
              path: '/generateSupplierOrderDetails',
              menuKey: 'yms_generateSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006597'),
              path: '/wms/pickList',
              menuKey: 'yms_wms_pickList'
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002389'),
              path: '/wms/pickListDetails',
              menuKey: 'yms_wms_pickListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005786'),
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006598'),
              path: '/wms/sortOrderSetting',
              menuKey: 'yms_wms_sortOrderSetting'
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001823'),
              path: '/wms/sorting',
              menuKey: 'yms_wms_sorting'
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001825'),
              path: '/wms/packWorking',
              menuKey: 'yms_wms_packWorking'
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001826'),
              path: '/sortingOut',
              menuKey: 'yms_sortingOut'
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006599'),
              path: '/handoverSheetManage',
              menuKey: 'yms_handoverSheetManage',
              children: [
                {
                  name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006600'),
                  path: '/handoverSheetManageDetails',
                  menuKey: 'yms_handoverSheetManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006601'),
      icon: '&#xe668;',
      type: 'supplierList',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006602'),
          path: '/supplierList',
          menuKey: 'yms_supplierList'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006603'),
          path: '/supplierAuditList',
          menuKey: 'yms_supplierAuditList'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006604'),
          path: '/supplierLevel',
          menuKey: 'yms_supplierLevel'
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006605'),
      icon: '&#xe699;',
      type: 'distributorList',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006606'),
          path: '/customerList',
          menuKey: 'yms_customerList',
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002327'),
          path: '/customerLabel',
          menuKey: 'yms_customerLabel',
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002326'),
          path: '/customerGroups',
          menuKey: 'yms_customerGroups',
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002328'),
          path: '/customerGrade',
          menuKey: 'yms_customerGrade'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006607'),
          path: '/tuokeLink',
          menuKey: 'yms_tuokeLink'
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001037'),
      icon: '&#xe668;',
      type: 'supplierBillManage',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006608'),
          path: '/supplierBillManage',
          menuKey: 'yms_supplierBillManage',
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006609'),
              path: '/supplierBillDetails',
              menuKey: 'yms_supplierBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002090'),
          path: '/customerBill',
          menuKey: 'yms_customerBill',
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006610'),
              path: '/customerBillDetails',
              menuKey: 'yms_customerBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002091'),
          path: '/logisticsBill',
          menuKey: 'yms_logisticsBill',
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006611'),
              path: '/logisticsBillDetails',
              menuKey: 'yms_logisticsBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002092'),
          path: '/warehouseBill',
          menuKey: 'yms_warehouseBill',
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006612'),
              path: '/warehouseBillDetails',
              menuKey: 'yms_warehouseBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002721'),
          path: '/financialJournal',
          menuKey: 'yms_financialJournal'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002085'),
          path: '/rechargeRecord',
          menuKey: 'yms_rechargeRecord'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006613'),
          path: '/passwordManage',
          menuKey: 'yms_passwordManage'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002084'),
          path: '/subsidyReview',
          menuKey: 'yms_subsidyReview'
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006614'),
      icon: '&#xe68e;',
      type: 'logisticsManage',
      children: [
        {
          name: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002892')}`,
          path: '/ymsLogistics',
          menuKey: 'yms_ymsLogistics'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006615'),
          path: '/basicLogistics',
          menuKey: 'yms_basicLogistics'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006616'),
          path: '/smartLogisticsRule',
          menuKey: 'yms_smartLogisticsRule'
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001052'),
      icon: '&#xe68f;',
      type: 'warehouseManage',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006617'),
          path: '/warehouseManage',
          menuKey: 'yms_warehouseManage'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006618'),
          path: '/reservoirManage',
          menuKey: 'yms_reservoirManage'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006619'),
          path: '/locationManage',
          menuKey: 'yms_locationManage'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006620'),
          path: '/messageNotification',
          menuKey: 'yms_messageNotification'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001557'),
          path: '/exchangeRateConfig',
          menuKey: 'yms_exchangeRateConfig'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006621'),
          path: '/containerManage',
          menuKey: 'yms_containerManage'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006622'),
          path: '/containerSettings',
          menuKey: 'yms_containerSettings'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006623'),
          path: '/addressLibraryManage',
          menuKey: 'yms_addressLibraryManage'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006624'),
          path: '/processConfiguration',
          menuKey: 'yms_processConfiguration'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006625'),
          path: '/iossTaxRateSetting',
          menuKey: 'yms_iossTaxRateSetting'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000915'),
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006626'),
              path: '/declarationRules',
              menuKey: 'yms_declarationRules'
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001036'),
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006627'),
              path: '/automaticDistributionRules',
              menuKey: 'yms_automaticDistributionRules'
            }
          ]
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006628'),
      icon: '&#xe68b;',
      type: 'helpCenter',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000036'),
          path: '/helpCenter',
          menuKey: 'yms_helpCenter',
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006629'),
          path: '/questionnaireInvestigation',
          menuKey: 'yms_questionnaireInvestigation',
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002025'),
              path: '/questionnaireStatistics',
              menuKey: 'yms_questionnaireStatistics',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002119'),
              path: '/addQuestionnaire',
              menuKey: 'yms_addQuestionnaire',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006630'),
              path: '/editQuestionnaire',
              menuKey: 'yms_editQuestionnaire',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006631'),
          path: '/operatingInstructions',
          menuKey: 'yms_operatingInstructions',
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000799'),
          id: 'toSystemAnnouncement',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'yms_systemAnnouncement',
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006632'),
      icon: '&#xe691;',
      type: 'dataCenter',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002082'),
          path: '/deliveryDetails',
          menuKey: 'yms_deliveryDetails',
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006633'),
      icon: '&#xe66a;',
      type: 'activityManage',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006634'),
          path: '/siteManage',
          menuKey: 'yms_siteManage',
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006635'),
          path: '/activityManage',
          menuKey: 'yms_activityManage',
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006636'),
          path: '/editActivity',
          menuKey: 'yms_editActivity',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006637'),
          path: '/advertisingManage',
          menuKey: 'yms_advertisingManage',
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003925'),
          path: '/rotationChart',
          menuKey: 'yms_rotationChart',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003892'),
          path: '/vajraDistrict',
          menuKey: 'yms_vajraDistrict',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003926'),
          path: '/topAdvertisingSpace',
          menuKey: 'yms_topAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003927'),
          path: '/sideAdvertisingSpace',
          menuKey: 'yms_sideAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003928'),
          path: '/infoAdvertisingSpace',
          menuKey: 'yms_infoAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002894'),
          path: '/subsidyRules',
          menuKey: 'yms_subsidyRules',
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006638'),
          path: '/distributorFeeAllocation',
          menuKey: 'yms_distributorFeeAllocation',
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000285'),
      path: '/messageCenter',
      icon: '&#xe8be;',
      menuKey: 'yms_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      type: 'messageCenter',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006639'),
          path: '/messageMailboxes',
          menuKey: 'yms_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006640'),
      icon: '&#xe693;',
      path: '/employeeList',
      menuKey: 'yms_employeeList',
      type: 'employeeList',
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006641'),
      icon: '&#xe664;',
      type: 'importTask',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006642'),
          path: '/importTask',
          menuKey: 'yms_importTask'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006643'),
          path: '/exportTask',
          menuKey: 'yms_exportTask'
        }
      ]
    }
  ]
};
